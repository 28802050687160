import React from "react";

export default function CloseIcon(props) {
  return (
    <svg
      width="19"
      height="19"
      viewBox="0 0 19 19"
      fill="none"
      className={
        "origin-center transition duration-200 rounded-full ease-in-out " +
        (props.isOpen && "transform rotate-45")
      }
      xmlns="http://www.w3.org/2000/svg"
    >
      <g opacity="1">
        <rect
          x="7.99219"
          y="0.712891"
          width="2.272"
          height="18.176"
          fill={"#fff"}
        />
        <rect
          x="18.4453"
          y="8.4375"
          width="2.272"
          height="18.176"
          transform="rotate(90 18.4453 8.4375)"
          fill={"#fff"}
        />
      </g>
    </svg>
  );
}
