import React from "react";
import Container from "./Container";
import Footer from "./Footer";
// import LOGO_white from "./LOGO_white.png";
import Header from "./Header";
import PageTitle from "./PageTitle";

export default function Contact() {
  return (
    <div className={`w-full h-full font-sans`}>
      <Header />
      <PageTitle text={"contact us"} />
      <div className="w-full bg-g-1 py-48">
        <Container justifyBetween={true} footer={true}>
          <div className="flex flex-col items-start mb-16 sm:mb-0">
            <a
              href="mailto:info@acacia.blue"
              target="_blank"
              className="text-primary underline"
              rel="noreferrer"
            >
              info@acacia.blue
            </a>
            <a
              href="mailto:media@acacia.blue"
              target="_blank"
              className="text-primary underline"
              rel="noreferrer"
            >
              media@acacia.blue
            </a>
          </div>
          <div className="text-bg">
            <p>500 Fifth Avenue</p>
            <p>New York, NY 10110</p>
          </div>
        </Container>
      </div>
      <Footer contactPage={true} />
    </div>
  );
}
