import React from "react";
// import Container from "./Container";
import VideoShort from "./video-short.mp4";
import Footer from "./Footer";
// import LOGO_white from "./LOGO_white.png";
import Header from "./Header";

export default function Home() {
  return (
    <div className={`w-full h-screen font-sans bg-primaryHome`}>
      <Header homepage={true} />
      <div className="relative z-10">
        <video
          // className="relative"
          autoPlay
          playsInline
          muted
          id="myVideo"
        >
          <source src={VideoShort} type="video/mp4"></source>
        </video>
        <div className="w-full bg-primaryHome flex flex-col sm:flex-row items-start justify-end p-4 pt-2 md:pb-2 md:hidden ">
          <p className="sm:w-600 text-g-1 text-center font-sans md:text-lg">
            acacia blue is a special purpose acquisition platform led by a team
            who are defined by our deep and diverse operating experience. We are
            focused on partnering with dynamic leaders to accelerate the growth
            of globally-relevant brands that have a powerful emotional
            connection with their consumers.
          </p>
        </div>
      </div>

      <Footer homepage={true} />
    </div>
  );
}
