import React from "react";
import Container from "./Container";
import LogoWhite from "./tree_white.png";
import LogoWhiteText from "./LOGO_white.png";

export default function Footer(props) {
  return (
    <div
      className={` bg-primary ${
        props.homepage ? " bottom-0 fixed" : "bottom-0 sm:fixed "
      }  w-full z-30 `}
    >
      {props.homepage && (
        <div className="w-full hidden md:block bg-primaryHome">
          <Container justifyEnd itemsCenter>
            <p
              style={{ transform: "translateY(-2px)" }}
              className="sm:w-700 text-g-1 text-right font-sans md:text-lg pt-0"
            >
              acacia blue is a special purpose acquisition platform led by a
              team who are defined by our deep and diverse operating experience.
              <br /> <br />
              we are focused on partnering with dynamic leaders to accelerate
              the growth of globally-relevant brands that have a powerful
              emotional connection with their consumers.
            </p>
          </Container>
        </div>
      )}
      {props.investors && (
        <a target="_blank" href=" https://sec.report/CIK/0001843370">
          <div className="bg-primaryHome flex-grow flex flex-col h-full">
            <Container justifyCenter={true}>
              <div className="flex flex-col items-center justify-center">
                <p className="text-g-1 text-center">SEC filings</p>
                <p className="text-g-1  text-center">
                  all filings for acacia blue available via EDGAR
                </p>

                <div className="flex items-center cursor-pointer select-none pt-2">
                  <p className="text-g-1 mr-2">view filings</p>{" "}
                  <svg
                    width="17"
                    height="10"
                    viewBox="0 0 17 10"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <line
                      x1="0.0175782"
                      y1="4.65552"
                      x2="16.354"
                      y2="4.65552"
                      stroke="#fff"
                    />
                    <path
                      d="M16.5642 4.66788C14.1074 4.66788 12.1157 2.63205 12.1157 0.120728"
                      stroke="#fff"
                    />
                    <path
                      d="M16.5642 4.66781C14.1074 4.66781 12.1157 6.70364 12.1157 9.21497"
                      stroke="#fff"
                    />
                  </svg>
                </div>
              </div>
            </Container>
          </div>
        </a>
      )}
      <Container
        footer={true}
        blueBG={true}
        justifyBetween={!props.contactPage}
      >
        <div
          className={`text-g-1 mb-4 sm:mb-0 ${
            props.contactPage && "opacity-0"
          }`}
        >
          <p className="text-center sm:text-left">500 Fifth Avenue</p>
          <p className="text-center sm:text-left">New York, NY 10110</p>
          <a
            href="mailto:info@acacia.blue"
            target="_blank"
            className={`
            text-g-1 text-center  block sm:hidden pt-4  ${
              props.contactPage && "opacity-0"
            }`}
            rel="noreferrer"
          >
            info@acacia.blue
          </a>
        </div>
        <img
          className={` w-24 hidden sm:block`}
          src={props.homepage ? LogoWhiteText : LogoWhite}
          alt="acacia blue"
        />

        <a
          href="mailto:info@acacia.blue"
          target="_blank"
          className={`
            text-g-1  hidden underline sm:block ${
              props.contactPage && "opacity-0"
            }`}
          rel="noreferrer"
        >
          info@acacia.blue
        </a>
        <img
          className={` w-24 mt-8 block sm:hidden`}
          src={props.homepage ? LogoWhiteText : LogoWhite}
          alt="acacia blue"
        />
      </Container>
    </div>
  );
}
