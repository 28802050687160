import React, { useState } from "react";
import { Link } from "react-router-dom";
import Container from "./Container";
// import LogoWhite from "./LOGO_white.png";
import LogoBlue from "./LOGO.png";
import MenuTray from "./MenuTray";

const navLinks = [
  { name: "the company", link: "/the-company" },
  { name: "our team", link: "/our-team" },
  { name: "image", link: "/" },
  { name: "investors", link: "/investors" },
  { name: "contact us", link: "/contact" },
];

export default function Header(props) {
  const [isOpen, setTray] = useState(false);
  return (
    <div
      className={`${props.homepage && " fixed top-0 "} w-full z-50 ${
        !props.homepage && " bg-g-1"
      }`}
    >
      <Container justifyBetween={true} blur={props.homepage}>
        {navLinks.map((item, i) => {
          if (i !== 2) {
            return (
              <Link to={item.link}>
                <h5 className={` ${"text-bg"} cursor-pointer hidden sm:block `}>
                  {item.name}
                </h5>
              </Link>
            );
          } else {
            return (
              <Link to={item.link}>
                <img
                  className={` w-24  ${
                    props.homepage ? "opacity-0" : "opacity-100"
                  }`}
                  src={LogoBlue}
                  alt="acacia blue"
                />
              </Link>
            );
          }
        })}
      </Container>
      <MenuTray isOpen={isOpen} setTray={setTray} homepage={props.homepage}>
        <Link to={"/"}>
          <img className={` w-24   mb-12 `} src={LogoBlue} alt="acacia blue" />
        </Link>
        <div className="flex flex-col space-y-2">
          <Link to={"/the-company"}>
            <p className="text-lg text-primary">the company</p>
          </Link>
          <Link to={"/our-team"}>
            <p className="text-lg text-primary">our team</p>
          </Link>

          <Link to={"/investors"}>
            <p className="text-lg text-primary">investors</p>
          </Link>

          <Link to={"/contact"}>
            <p className="text-lg text-primary">contact us</p>
          </Link>
        </div>
      </MenuTray>
    </div>
  );
}
