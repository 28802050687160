import React, { useState } from "react";
import Container from "./Container";
import Footer from "./Footer";
// import LOGO_white from "./LOGO_white.png";
import Header from "./Header";
import PdfIcon from "./pdf-icon.png";
import PageTitle from "./PageTitle";
import ModalAudit from "./ModalAudit";

const documentNames = [
  {
    name: "Audit Committee Charter",
    link: "https://drive.google.com/file/d/1BXtoKXNwx0ty3TPYektrZqHSJD9ygDyD/view?usp=sharing",
  },
  {
    name: "Corporate Governance Guidelines",
    link: "https://drive.google.com/file/d/1OPK5i57MNXYjNS-1NJPphlY4FYR3QYWA/view?usp=sharing",
  },
  {
    name: "Compensation Committee Charter",
    link: "https://drive.google.com/file/d/1oCJIfV4pqJ_o0fTzTAf5a1cIJc5Tr0xt/view?usp=sharing",
  },
  {
    name: "Code of Ethics",
    link: "https://drive.google.com/file/d/1kSX64eqp08JrzPkctcOhUMMgmCuEDuQJ/view?usp=sharing",
  },
  {
    name: "Nominating and Corporate Governance Committee Charter",
    link: "https://drive.google.com/file/d/15T-y8h7p3RkqRUT742BBGHohiOI8AVIE/view?usp=sharing",
  },
];

const audit = {
  // column LEFT
  name: "contacting the audit committee",
  role: "",
  shortBio: "",
  longBio: `you can contact bleuacacia ltd's audit committee of the board of directors to submit concerns or complaints regarding accounting, internal accounting controls or auditing matters at the following address
  \n
  \n
    audit committee
    bleuacacia ltd
    500 5th Avenue
    New York, NY 10110

    you may submit your concern anonymously or confidentially. please note, however, that if you do not provide contact information and the report does not provide enough information to enable investigation of the matter, the audit committee may find it difficult to fully investigate the reported matter. therefore, it is important that all reports be as complete and thorough as possible`,
};

export default function Investors(props) {
  const [modalOpen, setModalOpen] = useState(false);
  const [focused, setFocused] = useState(null);
  return (
    <div className={`w-full h-full font-sans`}>
      {focused && (
        <ModalAudit
          focused={focused}
          setFocused={setFocused}
          modalOpen={modalOpen}
          onClose={() => setModalOpen(false)}
        />
      )}
      <Header />

      <PageTitle text="investors" />
      <div className="w-full bg-g-1 py-16 pb-96">
        <Container footer={true} justifyBetween={true} itemsStart={true}>
          <div className="flex flex-col items-start mb-16 sm:mb-0">
            <h3 className="mb-4">press releases</h3>
            <a
              href="https://www.globenewswire.com/news-release/2021/11/18/2337266/0/en/acacia-blue-Announces-Pricing-of-Upsized-240-Million-Initial-Public-Offering.html"
              target="_blank"
              rel="noreferrer"
            >
              <div className="flex flex-col sm:flex-row justify-between ">
                <div className="hidden sm:flex flex-col items-center justify-center bg-primary px-4 py-8 text-g-1">
                  <h5 className="text-2xl">18</h5>
                  <p>november, 2021</p>
                </div>
                <div className="sm:hidden flex items-center justify-start bg-primary px-4 py-2 text-g-1">
                  <p>november 18, 2021</p>
                </div>
                <div className="bg-primaryLight flex items-center ">
                  <p className="text-primary sm:w-72 w-full px-4 py-4">
                    bleuacacia ltd announces pricing of uspsized $240 million
                    Initial Public Offering
                  </p>
                </div>
              </div>
            </a>
            {props.loadMore && (
              <div className="flex items-center mt-4 cursor-pointer">
                <p className="text-primary mr-2">load more</p>
                <svg
                  width="17"
                  height="10"
                  viewBox="0 0 17 10"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <line
                    x1="0.0175782"
                    y1="4.65552"
                    x2="16.354"
                    y2="4.65552"
                    stroke="#137DA5"
                  />
                  <path
                    d="M16.5642 4.66788C14.1074 4.66788 12.1157 2.63205 12.1157 0.120728"
                    stroke="#137DA5"
                  />
                  <path
                    d="M16.5642 4.66781C14.1074 4.66781 12.1157 6.70364 12.1157 9.21497"
                    stroke="#137DA5"
                  />
                </svg>
              </div>
            )}
          </div>
          <div className="flex flex-col items-start ">
            <h3 className="mb-4">governance documents</h3>
            {documentNames.map((link) => {
              return (
                <a href={link.link} target="_blank" rel="noreferrer">
                  <div className="flex items-center mb-2 cursor-pointer">
                    <img className="h-6" src={PdfIcon} alt="a pdf document" />{" "}
                    <p className="ml-2 text-primary text-sm sm:text-md">
                      {link.name}
                    </p>
                  </div>
                </a>
              );
            })}
            <div
              onClick={() => setFocused(audit)}
              className="flex items-center mt-4 cursor-pointer"
            >
              <p className="text-primary mr-2">contact the audit committee</p>
              <svg
                width="17"
                height="10"
                viewBox="0 0 17 10"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <line
                  x1="0.0175782"
                  y1="4.65552"
                  x2="16.354"
                  y2="4.65552"
                  stroke="#137DA5"
                />
                <path
                  d="M16.5642 4.66788C14.1074 4.66788 12.1157 2.63205 12.1157 0.120728"
                  stroke="#137DA5"
                />
                <path
                  d="M16.5642 4.66781C14.1074 4.66781 12.1157 6.70364 12.1157 9.21497"
                  stroke="#137DA5"
                />
              </svg>
            </div>
          </div>
        </Container>
      </div>

      <Footer investors={true} />
    </div>
  );
}
