// Ensures the root container is always 100vw, min 100vh, and centers all children along the y-axis
export default function Container(props) {
  const getAlign = () => {
    if (props.itemsEnd) {
      return "items-end";
    } else if (props.itemsStart) {
      return "items-start";
    } else {
      return `items-center`;
    }
  };

  const getJustify = () => {
    if (props.justifyBetween) {
      return `${
        props.footer
          ? "  justify-start sm:justify-between items-start"
          : "justify-between"
      }`;
    } else if (props.justifyAround) {
      return "justify-around";
    } else if (props.justifyEnd) {
      return "justify-end";
    } else if (props.justifyStart) {
      return "justify-start";
    } else {
      return "justify-center";
    }
  };

  return (
    <div
      className={`flex w-full justify-center items-center p-4 ${
        props.blur && " blurBG hidden sm:flex "
      } ${props.blueBG && " bg-primary"} `}
    >
      <div
        className={`md:max-w-screen-lg xl:max-w-screen-lg 2xl:max-w-screen-xl flex w-full ${getAlign()} ${getJustify()}   ${
          props.footer && "  flex-col  sm:flex-row justify-start"
        }`}
      >
        {props.children}
      </div>
    </div>
  );
}
