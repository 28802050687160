import React from "react";
import Container from "./Container";
import Footer from "./Footer";
// import LOGO_white from "./LOGO_white.png";
import Header from "./Header";
import PageTitle from "./PageTitle";
import VideoLong from "./video-long.mp4";
import circle1 from "./circle1.png";
import circle2 from "./circle2.png";
import circle3 from "./circle3.png";
import circle4 from "./circle4.png";
import circle5 from "./circle5.png";
import circle6 from "./circle6.png";

const circles = [circle1, circle2, circle3, circle4, circle5, circle6];

export default function Investors() {
  return (
    <div className={`w-full h-full font-sans`}>
      <Header />
      <PageTitle text="the company" />
      <div className="w-full bg-g-1 py-8">
        <Container>
          <video
            // className="relative"
            autoPlay
            playsInline
            controls
            loop={true}
            className="w-full"
          >
            <source src={VideoLong} type="video/mp4"></source>
          </video>
        </Container>
        <Container justifyCenter>
          <p className="text-center sm:w-3/4">
            a tenured team with a diverse set of operating experiences,
            passionate about opportunities to help exceptional leaders
            accelerate the growth of their business through the public markets
          </p>
        </Container>
        <Container>
          {" "}
          <div style={{ transform: "rotate(90deg)" }}>
            <svg
              width="43"
              height="20"
              viewBox="0 0 17 10"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <line
                x1="0.0175782"
                y1="4.65552"
                x2="16.354"
                y2="4.65552"
                stroke="#137DA5"
              />
              <path
                d="M16.5642 4.66788C14.1074 4.66788 12.1157 2.63205 12.1157 0.120728"
                stroke="#137DA5"
              />
              <path
                d="M16.5642 4.66781C14.1074 4.66781 12.1157 6.70364 12.1157 9.21497"
                stroke="#137DA5"
              />
            </svg>
          </div>
        </Container>

        <Container>
          {" "}
          <div className="w-full flex-col sm:flex-row flex justify-between items-center space-y-16 sm:space-y-0 pt-24 pb-8">
            {circles.map((circle, i) => {
              if (i <= 2) {
                return (
                  <img
                    className="h-auto w-2/3 sm:h-48 sm:w-auto"
                    src={circle}
                    alt="bullet point"
                  />
                );
              } else return null;
            })}
          </div>
        </Container>
        <Container>
          {" "}
          <div className="w-full flex-col sm:flex-row flex justify-between items-center space-y-16 sm:space-y-0 sm:pt-24 pb-32">
            {circles.map((circle, i) => {
              if (i > 2) {
                return (
                  <img
                    className="h-auto w-2/3 sm:h-48 sm:w-auto"
                    src={circle}
                    alt="bullet point"
                  />
                );
              } else return null;
            })}
          </div>
        </Container>
      </div>
      <Footer />
    </div>
  );
}
