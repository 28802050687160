/* eslint-disable react-hooks/exhaustive-deps */
import React, { useRef, useEffect, useLayoutEffect } from "react";
import CloseIcon from "./CloseIcon.js";

export default function Modal(props) {
  useLockBodyScroll();

  // Hook
  function useLockBodyScroll() {
    useLayoutEffect(() => {
      // Get original body overflow
      const originalStyle = window.getComputedStyle(document.body).overflow;
      // Prevent scrolling on mount
      document.body.style.overflow = "hidden";
      // Re-enable scrolling when component unmounts
      return () => (document.body.style.overflow = originalStyle);
    }, []); // Empty array ensures effect is only run on mount and unmount
  }

  function useOutsideAlerter(ref, sideEffec) {
    useEffect(() => {
      /**
       * Alert if clicked on outside of element
       */
      function handleClickOutside(event) {
        if (ref.current && !ref.current.contains(event.target)) {
          sideEffec(null);
          // document.body.setAttribute("style", "");
          // window.scroll({ top: props.offset, left: 0, behavior: "auto" });
        }
      }

      // Bind the event listener
      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        // Unbind the event listener on clean up
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, [ref, sideEffec]);
  }

  function exitModal() {
    console.log("ran exit modal");
    props.setFocused(null);
    console.log("new focus", props.focused);
  }

  useEffect(() => {
    // if you just opened it then there's no need for this
    console.log(props.focused, "in the modal.js");
    // if (!props.focused) {
    //   document.body.setAttribute("style", "");
    //   window.scroll({ top: props.offset, left: 0, behavior: "auto" });
    // }
  });

  const wrapperRef = useRef(null);
  const containerRef = useRef(null);
  useOutsideAlerter(wrapperRef, props.setFocused);
  // const handleScroll = (e) => console.log(e.target, "was scrolled");
  // useScrollListener(containerRef, handleScroll);

  return (
    <div
      //   onClick={() => props.setModal(!props.modal)}
      className={`modal_bg bg-g-1 sm:bg-modal md:bg-bg justify-center flex  modal_bg${
        props.focused !== null && "_toggled"
      }`}
      ref={containerRef}
      style={{
        "::-webkit-scrollbar": {
          display: "none",
        },
        "-ms-overflow-style": {
          display: "none",
        },
        "scrollbarWidth": "none",
      }}
    >
      {props.focused && (
        <div
          style={{
            "::-webkit-scrollbar": {
              display: "none",
            },
            "-ms-overflow-style": {
              display: "none",
            },
            "scrollbarWidth": "none",
          }}
          key={props.focused.name}
          className="modalCard bg-g-1 lg:bg-opacity-0 w-full h-auto flex items-center"
        >
          <div
            ref={wrapperRef}
            style={{
              "::-webkit-scrollbar": {
                display: "none",
              },
              "-ms-overflow-style": {
                display: "none",
              },
              "scrollbarWidth": "none",
            }}
            className="p-8 flex flex-col lg:flex-row mt-4 items-start h-full w-full bg-g-1"
          >
            <div className="justify-between items-center flex lg:hidden w-full pt-8 lg:pt-0 mb-8">
              <h6 className="text-bg text-4xl mr-8 font-semibold">
                {props.focused.name}
              </h6>
              <div
                onClick={() => exitModal()}
                className="bg-primary flex items-center justify-center p-4 cursor-pointer"
              >
                <CloseIcon isOpen={true} />
              </div>
            </div>
            <img
              className="w-72 min-w-72 h-auto"
              src={props.focused.photo}
              alt={props.focused.name}
            />
            <div className="flex flex-col lg:ml-8 w-full">
              <div className="justify-between items-center hidden lg:flex  w-full mb-8 pt-4">
                <h6 className="text-bg text-4xl mr-8 font-semibold font-header ">
                  {props.focused.name}
                </h6>
                <div
                  onClick={() => exitModal()}
                  className="bg-primary flex items-center justify-center p-4 cursor-pointer"
                >
                  <CloseIcon isOpen={true} />
                </div>
              </div>
              <div className="flex justify-start items-start w-full mt-8 lg:mt-0">
                <div className="text-primary flex-col mr-8 flex items-start ">
                  <p className="text-lg">{props.focused.role}</p>
                </div>
              </div>
              <p className="text-bg mt-8 mb-16">{props.focused.longBio}</p>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
