import React from "react";
import Container from "./Container";

export default function PageTitle(props) {
  return (
    <div className=" w-full bg-g-1 font-header">
      <Container blueBG={true} justifyStart itemsCenter>
        <h2
          style={{ lineHeight: "48%", transform: "translateY(-2px)" }}
          className="text-g-1 text-2xl  py-4 font-medium"
        >
          {props.text}
        </h2>
      </Container>
    </div>
  );
}
