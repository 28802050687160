import React, { useEffect } from "react";
import ReactGA from "react-ga";
import "./App.css";
import Home from "./Home";
import Contact from "./Contact";
import Investors from "./Investors";
import OurTeam from "./OurTeam";
import TheCompany from "./TheCompany.js";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  // Redirect,
} from "react-router-dom";

const TRACKING_ID = "UA-213944738-1"; // tracking id

function InitializeReactGA(ReactGA) {
  if (!window.GA_INITIALIZED) {
    ReactGA.initialize(TRACKING_ID);
    window.GA_INITIALIZED = true;
  }
}

function usePageViews() {
  const location = window.location;
  useEffect(() => {
    InitializeReactGA(ReactGA);
    ReactGA.set({ page: window.location.pathname });
    ReactGA.pageview(window.location.pathname);
  }, [location]);
}

export default function App() {
  // const [focused, setFocused] = useState(null);
  // const [modalOpen, setModalOpen] = useState(false);

  // console.log(focused);
  usePageViews();
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/investors" element={<Investors />} />
        <Route path="/the-company" element={<TheCompany />} />
        <Route path="/our-team" element={<OurTeam />} />
      </Routes>
    </Router>
  );
}
